@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .shine-effect {
        @apply overflow-hidden;
    }
    .shine-effect::before {
        content: "";
        @apply absolute -top-1/2 -left-1/2 w-[200%] h-[200%] bg-shine-gradient animate-shine;
        transform: translate(-50%, -50%);
    }
}

@media (min-width: 1024px) {
    .chartjs-pie {
        min-height: 400px;
    }
}
